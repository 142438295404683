import { useAppSelector } from '@/lib/hooks';
import type { Account } from '@/types';

const useAccountData = (): Account => {
  const account = useAppSelector((state) => state.account);

  return account;
};

export default useAccountData;
