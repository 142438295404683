export const LOGIN_METRIC_NAME = 'login';

export enum CheckStatus {
  ERROR = 'error',
  ERROR_NOT_ELIGIBLE = 'error_not_eligible',
  SUCCESS_EMAIL = 'success_email',
  EXPIRED = 'expired',
}

export enum ApiEndpoint {
  // this might be removed / replaced for MOI..
  EMAIL = '/api/my-order/check-email',
}

export enum MetricStep {
  EMAIL_SUBMITTED = 'email_submitted',
  EMAIL_SUCCESS = 'email_success',
  EMAIL_ERROR = 'email_error',
  EMAIL_VALIDATION_SUCCESS = 'email_validation_success',
  EMAIL_VALIDATION_ERROR = 'email_validation_error',
  OTP_SUBMITTED = 'otp_submitted',
  OTP_ERROR = 'otp_error',
  OTP_RESEND_CODE = 'otp_resend_code',
  OTP_RESEND_CODE_ERROR = 'otp_resend_code_error',
  OTP_SUCCESS = 'otp_success',
  GET_ORDERS_SUCCESS = 'get_orders_success',
  GET_ORDERS_ERROR = 'get_orders_error',
}
