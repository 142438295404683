import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import { type FaqData, FAQS } from '@/app/const';

interface FaqText {
  id: string;
  q: string;
  a: string;
}

export interface UseFaqsOptions {
  ids?: FaqData['id'][];
}

// Optionally pass in a list of id's (alternatively show all FAQ's)
const useFaqs = ({ ids }: UseFaqsOptions): FaqText[] => {
  const t = useTranslations();
  const tValues = {};

  // get list of FAQs in order of id's passed in
  const list = useMemo(() => {
    if (ids?.length) {
      return ids.reduce((arr, id) => {
        const cur = FAQS.find((faq) => faq.id === id);
        return cur ? [...arr, cur] : arr;
      }, [] as FaqData[]);
    }

    return FAQS;
  }, [ids]);

  return list.map(({ id, q, a }) => ({
    id,
    q: t(q, tValues),
    a: t(a, tValues),
  }));
};

export default useFaqs;
