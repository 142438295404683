import { cx } from 'class-variance-authority';
import { useFormatter, useTranslations } from 'next-intl';
import { PropsWithChildren, ReactNode } from 'react';

import type { Address } from '@/types';

import AddressDisplay from '../AddressDisplay';
import SectionHeading from '../SectionHeading';

export interface OrderInfoProps {
  readonly className?: string;
  readonly address?: Address;
  readonly email?: string;
  readonly details?: {
    readonly number: string;
    readonly date: Date;
    readonly quantity: number;
  };
}

const Info = ({
  heading,
  children,
}: PropsWithChildren<{ readonly heading: ReactNode }>): JSX.Element => {
  return (
    <section className="flex w-full min-w-48 flex-1 shrink-0 flex-col md:w-auto md:shrink">
      <SectionHeading Element="h3" weight="bold">
        {heading}
      </SectionHeading>
      {children}
    </section>
  );
};

const OrderInfo = ({
  address,
  email,
  details,
  className,
}: OrderInfoProps): JSX.Element | null => {
  const t = useTranslations();
  const format = useFormatter();

  if (!address && !email && !details) {
    return null;
  }

  return (
    <div
      data-testid="order-info"
      className={cx('flex gap-4 flex-wrap', className)}
    >
      {details && (
        <Info heading={t('eop_order_number_text', { number: details.number })}>
          <ol>
            <li>
              {format.dateTime(details.date, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </li>

            <li>
              {t('eop_quantity_text', {
                number: details.quantity,
              })}
            </li>
          </ol>
        </Info>
      )}

      {address && (
        <Info heading={t('eop_order_shippingaddress')}>
          <AddressDisplay address={address} />
        </Info>
      )}

      {email && (
        <Info heading={t('eop_order_email')}>
          <p>{email}</p>
        </Info>
      )}
    </div>
  );
};

export default OrderInfo;
