'use client';

import { isBrowser } from '@jouzen/ecom-utils';
import { useCallback, useEffect, useState } from 'react';

const usePageView = (pageName: string): void => {
  const [pageLoaded, setPageLoaded] = useState(false);

  const pageViewed = useCallback(async () => {
    if (isBrowser()) {
      await window.analytics.page(pageName);
    }
  }, [pageName]);

  // handles page views on Component change/browser refresh only
  useEffect(() => {
    if (pageName && !pageLoaded) {
      pageViewed();
    }
    setPageLoaded(true);
  }, [pageName, pageLoaded, pageViewed]);
};

export default usePageView;
