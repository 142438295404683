const RING_TYPE_CODE = 'JZ90';

const removeEnterpriseCode = (skuItems: string[], sku: string) => {
  // Check if the SKU has an enterprise prefix (e.g., NT- or MIL-)
  if (skuItems.length > 2 && !skuItems[0].includes(RING_TYPE_CODE)) {
    return skuItems.slice(1).join('-');
  }

  // Return the original SKU for unprefixed ring SKUs
  return sku;
};

export class ParsedSku {
  sku: string;
  skuItems: string[];
  finishCode?: string;
  sizeCode?: string | null;
  withoutPrefix?: string | null;
  constructor(sku: string) {
    this.sku = sku;
    this.skuItems = sku.split('-');
    this.withoutPrefix = removeEnterpriseCode(this.skuItems, sku);
    const items = this.withoutPrefix.split('-');
    this.finishCode = items[1];
    this.sizeCode = items[2] || null;
  }

  isRing() {
    return this.sku.includes(RING_TYPE_CODE);
  }

  hasSize() {
    return !!this.sizeCode;
  }

  hasFinish() {
    return !!this.finishCode && this.finishCode.length > 4;
  }

  withoutEnterpriseCode() {
    if (!this.isRing()) {
      return this.sku;
    }
    return removeEnterpriseCode(this.skuItems, this.sku);
  }
}
