import { getCookie, setCookie } from '@jouzen/ecom-utils';

export function getEmailToken() {
  return getCookie('emailToken');
}

export async function setEmailTokenCookie(email: string) {
  fetch('/api/emailToken', {
    method: 'POST',
    body: JSON.stringify({ email }),
  })
    .then((res) => res.json())
    .then((data) => {
      setCookie('emailToken', data.emailToken, 60);
    });
}
