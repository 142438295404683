'use client';

import { TypographyV3 as Typography } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';

import { LineItem } from '@/types';

import Image from '../Image';
import SectionHeading from '../SectionHeading';

export interface LineItemProps {
  readonly className?: string;
  readonly lineItem: LineItem;
}

const OrderLineItem = ({ className, lineItem }: LineItemProps): JSX.Element => {
  const { description, image, title } = lineItem;

  return (
    <div
      data-testid="order-line-item"
      className={cx(className, 'flex gap-4 items-center md:gap-5')}
    >
      <div className="flex-none">
        {image?.src ? (
          <Image
            data-testid="product-image"
            src={image.src}
            alt={image.alt}
            width={image.width}
            height={image.height}
            className={image.className}
          />
        ) : null}
      </div>

      <div className="flex-1">
        {title && (
          <SectionHeading Element="h3" weight="bold">
            {title}
          </SectionHeading>
        )}

        {description && <Typography Element="div">{description}</Typography>}
      </div>
    </div>
  );
};

export default OrderLineItem;
