import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Account } from '@/types';

import { RootState } from '../store';

export const initialState: Account = {
  email: '',
  orders: undefined,
};

/* Reducers */
const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    initializeAccount() {},

    // we encourage naming reducers as past-tense "this happened" names like emailAdded,
    // because we are describing "an event that occurred in the application"
    emailAdded: {
      reducer(state, action: PayloadAction<Account>) {
        state.email = action.payload.email;
      },
      prepare(email: string) {
        return {
          payload: { email },
        };
      },
    },

    accountAdded: {
      reducer(state, action: PayloadAction<Account>) {
        Object.assign(state, action.payload);
      },
      prepare(account: Account) {
        return { payload: { ...account } };
      },
    },

    resetAccount: {
      reducer(state, action: PayloadAction<Account>) {
        Object.assign(state, action.payload);
      },
      prepare() {
        return { payload: initialState };
      },
    },
  },
});

/* Actions */
export const { initializeAccount, emailAdded, accountAdded, resetAccount } =
  accountSlice.actions;

/* Selectors */
export const selectAccount = (state: RootState) => state.account;

export default accountSlice.reducer;
