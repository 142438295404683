import { Address } from '@/types';
import { ApiError } from '@/types/Error';
import { logToDataDog } from '@/utils/logToDatadog';

type GetOrderLineItem = {
  skuCode: string;
  quantity?: number;
};

type GetOrders = {
  netsuiteOrderId?: string;
  lineItems: GetOrderLineItem[];
  shippingAddress: Address;
};

export type GetOrdersResponse = {
  data?: GetOrders[];
  error?: ApiError;
};

export async function getOrders(email: string, emailToken: string | null) {
  try {
    const resp = await fetch('/api/getOrders', {
      method: 'POST',
      body: JSON.stringify({ email, emailToken }),
    });
    const response = await resp.json();
    return { data: response, error: response?.error } as GetOrdersResponse;
  } catch (error) {
    logToDataDog('api', 'Error calling getOrders', { emailToken, error });
  }
}
