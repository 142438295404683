import { getCookie } from '@jouzen/ecom-utils';

import { mocks } from '@/../mocks/apiMocks';
import { PartnerConfig } from '@/configs/flow';
import { accountAdded, emailAdded } from '@/lib/account/accountSlice';
import { orderAdded } from '@/lib/order/orderSlice';
import { Address } from '@/types';
import { logToDataDog } from '@/utils/logToDatadog';
import { ParsedSku } from '@/utils/parseSku';

import {
  LOGIN_METRIC_NAME,
  MetricStep,
} from '../components/ui/EmailForm/const';
import { getEmailToken } from './emailTokenHelper';
import { getOrders } from './getOrders';
import { validateEmail, ValidateEmailResponse } from './validateEmail';

interface HandlePostAuthParams {
  email: string;
  partnerConfig: PartnerConfig;
  metricIncrement: (
    name: string,
    options: { step: MetricStep; partner: string },
  ) => Promise<void>;
  dispatch: (action: any) => void;
  push: (path: string) => void;
  partner: string;
}

export async function handlePostAuth({
  email,
  partnerConfig,
  metricIncrement,
  dispatch,
  push,
  partner,
}: HandlePostAuthParams) {
  const emailToken = getEmailToken();
  const MOCK_DATA = getCookie('mock')?.toUpperCase();

  /* Partner requires email validation */
  if (partnerConfig.validateUser) {
    const response: ValidateEmailResponse = MOCK_DATA
      ? mocks[MOCK_DATA].email
      : await validateEmail(email, emailToken);

    if (
      response?.eligible &&
      response?.netsuiteInternalId &&
      response?.shippingAddress
    ) {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.EMAIL_VALIDATION_SUCCESS,
        partner,
      });
      dispatch(emailAdded(email));
      dispatch(
        orderAdded({
          netsuiteOrderId: response.netsuiteOrderId! ?? null,
          netsuiteInternalId: response.netsuiteInternalId!,
          shippingAddress: response.shippingAddress as Address,
          lineItems: response.skus?.length
            ? response.skus.map((sku) => ({
                sku: new ParsedSku(sku).withoutEnterpriseCode(),
                quantity: 1,
              }))
            : [],
          agreesToShare: response.orderComplete ? false : undefined,
          orderComplete: response.orderComplete,
        }),
      );
      if (response?.orderComplete) push(partnerConfig.completedStep);
      else push(partnerConfig.successStep);
    } else {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.EMAIL_VALIDATION_ERROR,
        partner,
      });
      await logToDataDog('auth', 'EOP Email Validation error', {
        partner,
        emailToken: emailToken,
        error: response.error ?? null,
      });
      push(partnerConfig.invalidStep);
    }
    /* Partner requires fetching orders from enterprise-orders */
  } else if (partnerConfig?.getOrders) {
    const response = MOCK_DATA
      ? mocks[MOCK_DATA].getOrders
      : await getOrders(email, emailToken);

    if (response?.data?.length) {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.GET_ORDERS_SUCCESS,
        partner,
      });
      dispatch(emailAdded(email));
      dispatch(
        accountAdded({
          orders: response?.data?.map((order) => {
            return {
              netsuiteOrderId: order.netsuiteOrderId,
              lineItems: order.lineItems?.map((item) => {
                const parsedSku = new ParsedSku(item.skuCode);
                // Set the sku if the ring selections were completed
                const sku =
                  parsedSku.hasSize() && parsedSku.hasFinish()
                    ? item.skuCode
                    : undefined;
                return {
                  originalSku: item.skuCode,
                  quantity: item.quantity,
                  sku: sku,
                };
              }),
              shippingAddress: order.shippingAddress,
            };
          }),
        }),
      );

      push(partnerConfig.successStep);
    } else {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.GET_ORDERS_ERROR,
        partner,
      });
      await logToDataDog('auth', 'EOP Get Orders error', {
        partner,
        emailToken: emailToken,
        error: response?.error ?? null,
      });
      push(partnerConfig.invalidStep);
    }

    /* No post auth requirements */
  } else {
    dispatch(emailAdded(email));
    push(partnerConfig.successStep);
  }
}
