'use client';

import { Button, FormControl } from '@jouzen/ecom-components';
import { getCookie } from '@jouzen/ecom-utils';
import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { EventType } from '@/analytics/types';
import { getEmailToken } from '@/app/utils/emailTokenHelper';
import { handlePostAuth } from '@/app/utils/handlePostAuth';
import { config } from '@/configs/flow';
import { useRouter } from '@/i18n/routing';
import { useAppDispatch } from '@/lib/hooks';
import { AuthorizationStep } from '@/types/AuthorizationSteps';
import { shouldAuthenticate } from '@/utils/auth/shouldAuthenticate';
import { logToDataDog } from '@/utils/logToDatadog';
import { metricIncrement } from '@/utils/reportMetrics';

import SubmitButton from '../SubmitButton';
import { CheckStatus, LOGIN_METRIC_NAME, MetricStep } from './const';

interface OTPFormProps {
  readonly setCheckStatus: Dispatch<SetStateAction<CheckStatus | null>>;
  readonly isLoading: boolean;
  readonly step: any;
  readonly submitForm: (
    formState: null | undefined,
    url: any,
    method: string | undefined,
  ) => Promise<void>;
  readonly clickLink: (url: string) => Promise<void>;
  readonly error?: string | null;
  readonly partner: string;
}

const OTPForm = ({
  setCheckStatus,
  isLoading,
  step,
  submitForm,
  clickLink,
  error,
  partner,
}: OTPFormProps): JSX.Element => {
  const [resendLoading, setResendLoading] = useState<boolean>(false);
  const { push } = useRouter();
  const partnerConfig = config[partner];
  const dispatch = useAppDispatch();
  const [submit, setSubmit] = useState<any>();
  const [resendLink, setResendLink] = useState<string | null>(null);
  const emailToken = getEmailToken();

  // Set step actions
  useEffect(() => {
    if (
      step?.name === AuthorizationStep.AUTHENTICATION &&
      step?.haapiResponse
    ) {
      const { model } = step.haapiResponse.actions[0];
      if (model) {
        setSubmit(model);
      }

      if (step.haapiResponse?.links?.length > 0) {
        const { href, title } = step.haapiResponse.links[0];
        if (title?.includes('Resend')) {
          setResendLink(href);
        }
      }
    }
  }, [step]);

  const resendCode = async () => {
    setResendLoading(true);
    if (resendLink) {
      await clickLink(resendLink)
        .then(async () => {
          await metricIncrement(LOGIN_METRIC_NAME, {
            step: MetricStep.OTP_RESEND_CODE,
            partner,
          });
          setResendLoading(false);
        })
        .catch(() => {
          setCheckStatus(CheckStatus.ERROR);
        });
    } else {
      setCheckStatus(CheckStatus.ERROR);
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.OTP_RESEND_CODE_ERROR,
        partner,
      });
      await logToDataDog('auth', 'EOP OTP Form error', {
        partner,
        emailToken,
        error: 'error resending OTP code',
      });
    }
  };

  const trackSuccess = async (): Promise<void> => {
    await window.analytics.track(EventType.CTAClicked, {
      cta: 'submit',
      action: 'submit email',
      location: 'body',
      path: '/email',
    });
  };

  const handleSubmit = async (values: any) => {
    await metricIncrement(LOGIN_METRIC_NAME, {
      step: MetricStep.OTP_SUBMITTED,
      partner,
    });
    const email = getCookie('fake_session');
    if (!shouldAuthenticate(email)) {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.OTP_SUCCESS,
        partner,
      });

      await handlePostAuth({
        email: email!,
        partnerConfig,
        metricIncrement,
        dispatch,
        push,
        partner,
      });
    } else {
      await submitForm(values, submit.href, submit.method)
        .then(trackSuccess)
        .catch(async (error) => {
          setCheckStatus(CheckStatus.ERROR);
          await metricIncrement(LOGIN_METRIC_NAME, {
            step: MetricStep.OTP_ERROR,
            partner,
            error,
          });
          await logToDataDog('auth', 'EOP OTP Form error', {
            partner,
            emailToken,
            error: JSON.stringify(error),
          });
        });
    }
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: getFormValidationSchema,
    initialValues: {
      otp: '',
    },
    onSubmit: handleSubmit,
  });

  return (
    <form
      className="flex w-full flex-col gap-2"
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <FormControl
        className="w-full"
        control="TextInput"
        inputMode="numeric"
        errorMessage={formik.errors?.otp || error}
        label="Sign-in Code"
        maxLength={80}
        name="otp"
        onChange={formik.handleChange}
        required
        value={formik.values?.otp}
      />

      <SubmitButton
        loading={isLoading}
        full
        type="submit"
        disabled={isLoading || !submit}
      >
        Submit
      </SubmitButton>

      <Button loading={resendLoading} onClick={() => resendCode()} type="reset">
        Resend Code
      </Button>
    </form>
  );
};

export default OTPForm;

function getFormValidationSchema() {
  return Yup.object().shape({
    otp: Yup.string()
      .trim()
      .required(() => 'Required')
      .max(60, () => 'Max 6 characters'),
  });
}
