import { debugAnalytics } from '@/utils/debugAnalytics';

import type { MiddlewareFunction } from './types';

const withCheckUserId: MiddlewareFunction = ({ payload, next }): void => {
  if (!payload.obj?.userId) {
    next(payload);
  } else {
    debugAnalytics(`userId is not removed`, {
      data: { payload },
      level: 'error',
      logToDatadog: 'withCheckUserId',
    });
  }
};

export default withCheckUserId;
