'use client';

import {
  TypographyV3 as Typography,
  TypographyV3Props as TypographyProps,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';

import useFaqs, { type UseFaqsOptions } from '@/app/hooks/useFaqs';

export interface FaqsProps extends Omit<TypographyProps, 'children'> {
  className?: string;
  ids?: UseFaqsOptions['ids'];
}

export const Faqs = ({
  ids,
  className,
  variant = 'body-large',
  ...typographyProps
}: Partial<FaqsProps>) => {
  const list = useFaqs({ ids });

  if (!list.length) {
    return null;
  }

  return (
    <Typography
      data-testid="faqs"
      Element="dl"
      className={cx(className)}
      variant={variant}
      {...typographyProps}
    >
      {list.map(({ id, q, a }) => (
        <div key={id}>
          <dt className="font-bold">{q}</dt>
          <dd>{a}</dd>
        </div>
      ))}
    </Typography>
  );
};

export default Faqs;
