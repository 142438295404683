import { debugAnalytics } from '@/utils/debugAnalytics';

import type { MiddlewareFunction } from './types';

const withCheckStatsigId: MiddlewareFunction = ({ payload, next }): void => {
  const { properties } = payload.obj;

  if (!properties?.statsigStableId) {
    debugAnalytics(`properties.statsigStableId is missing`, {
      data: { payload },
      level: 'error',
      logToDatadog: 'withCheckStatsigId',
    });
  }

  // continue, even if id is missing from payload
  next(payload);
};

export default withCheckStatsigId;
