import { getCookie } from '@jouzen/ecom-utils';

import { SEGMENT_ANONYMOUS_ID_COOKIE } from '@/app/contexts/ConsentManager';
import { debugAnalytics } from '@/utils/debugAnalytics';

import type { MiddlewareFunction } from './types';

const withCheckAnonymousId: MiddlewareFunction = ({ payload, next }): void => {
  const { anonymousId } = payload.obj;

  if (getCookie(SEGMENT_ANONYMOUS_ID_COOKIE) !== anonymousId) {
    next(payload);
  } else {
    debugAnalytics(`Anonymous ID is same as ecom`, {
      data: { payload },
      level: 'error',
      logToDatadog: 'withCheckAnonymousId',
    });
  }
};

export default withCheckAnonymousId;
