'use client';

import {
  GridItem,
  LayoutGrid,
  TypographyV3 as Typography,
} from '@jouzen/ecom-components';
import { PropsWithChildren, ReactNode } from 'react';

export interface SectionProps {
  readonly title: ReactNode | string;
  readonly className?: string;
}

const Section = ({
  title,
  children,
  className,
}: PropsWithChildren<SectionProps>) => {
  const isTitleString = typeof title === 'string';

  return (
    <section className={className}>
      <LayoutGrid rowGap={24} className="w-full">
        <GridItem colEnd={{ sm: 'main', md: 8 }} colStart={{ sm: 'main' }}>
          {isTitleString ? (
            <Typography Element="h2" variant="h5">
              {title}
            </Typography>
          ) : (
            title
          )}
        </GridItem>

        <GridItem colEnd={{ sm: 'main' }} colStart={{ sm: 'main', md: 9 }}>
          {children}
        </GridItem>
      </LayoutGrid>
    </section>
  );
};

export default Section;
