import { MessageKey } from '@/types';

/**
 * Please access this data using the useFaqs hook,
 * which will handle values & special cases.
 */

export interface FaqData {
  id: string;
  q: MessageKey;
  a: MessageKey;
}

// TODO: whenever we get this data

const FAQS: FaqData[] = [
  {
    id: 'demo-example',
    q: 'developer_secondary_title',
    a: 'developer_secondary_body',
  },
];

export default FAQS;
