import { debugAnalytics } from '@/utils/debugAnalytics';

import { IP } from './const';
import type { MiddlewareFunction } from './types';

const withCheckIp: MiddlewareFunction = ({ payload, next }): void => {
  if (payload.obj?.context?.ip === IP) {
    next(payload);
  } else {
    debugAnalytics(`IP Address is not removed`, {
      data: { payload },
      level: 'error',
      logToDatadog: 'withCheckIp',
    });
  }
};

export default withCheckIp;
