/**
 * TODO: update to include more or4 finishes
 * TODO: move this to ecom-content
 */

const SELECTOR_IMAGE: Record<string, string> = {
  black: 'product/wizard/or4-black-selector.png',
  silver: 'product/wizard/or4-silver-selector.png',
};

export default SELECTOR_IMAGE;
