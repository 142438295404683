'use client';

import {
  TypographyV3 as Typography,
  TypographyV3Props as TypographyProps,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import { MessageKey } from '@/types';

export interface HelpEmailLinkProps extends Omit<TypographyProps, 'children'> {
  readonly standalone?: boolean;
}

const HelpEmailLink = ({
  standalone: isStandalone,
  className,
  ...typographyProps
}: HelpEmailLinkProps): JSX.Element => {
  const t = useTranslations();

  // use different MessageKey based on props
  const msgKey: MessageKey = useMemo(() => {
    if (isStandalone) {
      // Need help? Email partnersupport@ouraring.com
      return 'eop_login_email_page_support_text';
    }
    // Email partnersupport@ouraring.com for assistance.
    return 'eop_need_additional_help_text';
  }, [isStandalone]);

  return (
    <Typography
      data-testid="help-email-link"
      className={cx(className, {
        'flex flex-wrap gap-x-1.5': isStandalone,
      })}
      {...typographyProps}
    >
      {t.rich(msgKey)}
    </Typography>
  );
};

export default HelpEmailLink;
