/**
 * Before sending to Segment Source:
 * - Transform & Enhance Segment payload
 * - Add checks & guardrails to all our Segment calls
 */

import type { MiddlewareFunction } from './types';
import withAnonymousId from './withAnonymousId';
import withCheckAnonymousId from './withCheckAnonymousId';
import withCheckHipaa from './withCheckHipaa';
import withCheckIp from './withCheckIp';
import withCheckStatsigId from './withCheckStatsigId';
import withCheckUserId from './withCheckUserId';
import withEnhancedPayload from './withEnhancedPayload';
import withEventTypes from './withEventTypes';
import withTryCatch from './withTryCatch';

const sourceMiddlewares: MiddlewareFunction[] = [
  withAnonymousId,
  withEnhancedPayload,
  withCheckStatsigId, // logs when statsig id is missing (does not stop)
  withEventTypes, // stops for bad event types
  withCheckAnonymousId, // stops when id matches cookie
  withCheckHipaa, // stops if required flags are missing
  withCheckIp, // stops if ip is not masked
  withCheckUserId, // stops if user id is not removed
  withTryCatch, // should always be last
];

export type { MiddlewareFunction };
export default sourceMiddlewares;
