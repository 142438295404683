import { getCookie, readFromLocalStorage } from '@jouzen/ecom-utils';

import { LOCALE_DEFAULT } from '@/types/LocaleMessageKey';
import { debugAnalytics } from '@/utils/debugAnalytics';

import { IP, USER_ID } from './const';
import getPageInfo from './getPageInfo';
import type { MiddlewareFunction, SegmentEvent, SegmentFacade } from './types';

const STATSIG_STABLE_ID_LOCAL_STORAGE_KEY = 'STATSIG_LOCAL_STORAGE_STABLE_ID';

const withEnhancedPayload: MiddlewareFunction = ({ payload, next }): void => {
  const obj: SegmentEvent = {
    ...payload.obj,
    context: {
      ...payload.obj.context,
      page: getPageInfo(),
      locale: getCookie('NEXT_LOCALE') ?? LOCALE_DEFAULT,
      traits: {
        ...payload.obj.context?.traits,
        isHipaa: true, // required for HIPAA
      },
      ip: IP,
    },

    properties: {
      ...payload.obj.properties,
      page: getPageInfo(),
      name: payload.obj.name,
      isHipaa: true, // required for HIPAA
      // Required to connect Statsig experiments with Segment events
      statsigStableId: readFromLocalStorage(
        STATSIG_STABLE_ID_LOCAL_STORAGE_KEY,
      ),
    },
    traits: {
      ...payload.obj.traits,
      isHipaa: true, // required for HIPAA
    },

    userId: USER_ID,
  };

  debugAnalytics(`enhanced payload`, { data: obj });

  next({ ...payload, obj } as SegmentFacade);
};

export default withEnhancedPayload;
